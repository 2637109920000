import React from "react";
import FounderCard from "../../components/FounderCard/Founder";

export default function PageAbout() {
  return (
    <div className="w-[90%] md:w-[80%]  mx-auto flex flex-col mt-10 md:mt-20 gap-6 md:gap-8">
       <div>
        <h1 className="text-lg md:text-xl font-semibold text-center">ABOUT US</h1>
      </div>
      <div className="text-sm md:text-base">
        QuriousBit is a game development company, based out of India,
        envisioning to disrupt casual puzzle genres with innovative core
        gameplay mechanics and deeper narratives. Our dream is to pin India on
        the global map of free-to-play casual mobile gaming industry.
      </div>
      <div>
        <h1 className="text-lg md:text-xl font-semibold text-center">OUR FOUNDERS</h1>
      </div>
      <div className="w-full flex flex-col md:flex-row items-center gap-4">
        <FounderCard
          name="Ramakrishna Reddy Y L"
          linkedin={"https://www.linkedin.com/in/ramakrishna-reddy-y-l-816aba79/"}
          description={"Founder & CEO"}
          email={"ram@quriousbit.com"}
          details={
            "With a wealth of experience in Product Management, Ram has forged an impressive career within the casual gaming industry."
          }
        />
        <FounderCard
          name="Shubham Joshi"
          description={"Founder & CTO"}
          email={"shubham@quriousbit.com"}
          linkedin={"https://www.linkedin.com/in/shubham-joshi-0aba33116/"}
          details={
            "With a strong engineering foundation, Shubham has led and developed high-impact solutions in the casual gaming industry."
          }
        />
      </div>
    </div>
  );
}
