import React, { useEffect, useState } from 'react';
import ReactMarkdown from 'react-markdown';

const PagePrivacyPolicy = () => {
  const [markdown, setMarkdown] = useState('');

  useEffect(() => {
    fetch('/quriousbit_privacy_policy.md')
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.text();
      })
      .then(text => setMarkdown(text))
      .catch(error => console.error('There was a problem with the fetch operation:', error));
  }, []);

  return (
    <div className="bg-white w-[80%] mx-auto text-neutral-800 mt-[60px]">
      <div className="px-10">
        <h1 style={{ textAlign: 'center', marginBottom: '20px' }}>
          QURIOUSBIT PRIVACY POLICY
        </h1>
        <p style={{ fontWeight: 'bold' }}>Effective date: October 20, 2023</p>
        <ReactMarkdown children={markdown} />
      </div>
    </div>
  );
};

export default PagePrivacyPolicy;
