import React from "react";
import JobDescriptionCard from "../../components/JobDescriptionCard/JobDescriptionCard";

export default function PageCareers() {
  return (
    <div className="w-[90%] md:w-[80%] mx-auto flex flex-col mt-10 md:mt-20 gap-6 md:gap-8">
      <div>
        <h1 className="text-lg md:text-xl font-semibold text-center">
          CAREERS
        </h1>
      </div>
      <div>
        <JobDescriptionCard
          jobTitle="1. Game Designer (Bangalore - 5 Years)"
          jobDescription={{
            responsibilities: [
              "Responsible for designing a casual mobile f2p game end to end – concept stage to prototyping to MVP design to deeper design to liveops",
              "Creating a 6 month detailed roadmap, 1 year high level roadmap and 2 year vision documents",
              "Building systems of game design- level design systems, core game systems, meta systems etc., and scaling them up",
              "Designing levels using mathematical modeling, analytics to achieve business KPIs",
              "Envisioning a simple, extensible narrative for the game franchise which sets the lighthouse for the player",
              "Setting up guidelines for game design, UX design, Art+UI design, Animation design",
              "Crafting fun-filled, player experiences across visceral, reflective and behavioral levels",
              "Delivering consistent game-play experience via game dialogue tone, BGM, sounds, narrative etc.",
              "Modeling a free-to-play game economy which drives both retention and monetization up",
              "Understanding key behavioral insights from quantitative and qualitative methods and decluttering the designer’s dilemma to come up with key hypotheses and testing them using A/B experimentation",
              "Brainstorming the scope of the design with the right stakeholders and coming up with detailed product specification documents including mockups, wireframes, animation mocks",
              "Identifying small optimizations to large feature ideas to drive the CLTV up",
              "Setting up an end-to-end design pipeline on Unity from concept design to UI and animation integrations",
              "Collaborating with Product Managers & Business Analysts to understand behavioral insights and improve monetization opportunities via both In-app-advertising and In-app-purchase",
            ],
            candidate: [
              "4+ years of f2p game design experience. Global casual mobile gaming experience would be a plus. We are open to freshers as well.",
              "Hands-on experience with tools like Unity, Adobe Suite, Figma, and a few other mockup tools",
              "Deep understanding of gamer needs and experience in delivering",
              "Ability to deconstruct a game to the design decision level",
              "Extraordinary articulation skills – oral, written, and presentation skills",
              "Hands-on experience in delivering impeccable product specification notes",
              "Knowledge of mathematics, statistics, and regression models",
              "Analytical appetite to process large behavioral data and draw design insights",
              "Humility to take and give constructive feedback regarding design iterations without demoralizing the team",
              "Conscious of the balance between creative freedom and efficient business execution",
              "A great team player who believes in creating a positive culture",
              "A person who enjoys gaming and is passionate about being in a gaming startup",
            ],
            contact: [
              "Interested candidates, please reach out to us by email at ram@quriousbit.com",
            ],
          }}
        />
        <JobDescriptionCard
          jobTitle="2. Marketing Manager (Bangalore - 5 Years)"
          jobDescription={{
            responsibilities: [
              "Responsible for user acquisition efforts end to end for mobile games and GTM strategy and market penetration",
              "Collaborate with Game development team to understand the opportunities to improve the game and thus increasing the organic installs/WOM/virality quotient of the game",
              "Understanding the latest market trends and behavioral insights from UA standpoint and feeding them back into game development team",
              "Identifying the areas of improvement in onboarding experiences and working with game development team to improve those areas and thus leading to higher ROAS of the campaigns",
              "Setting up accounts across leading digital ad networks and MMPs and establishing a good relationship with the third party stakeholders",
              "Establishing credit lines with ad networks and cracking business deals at attractive pricings",
              "Running multiple ad campaigns across various ad formats at the same time globally",
              "Setting up the data flow pipeline for entire UA system and building visualization layer on top of it",
              "Monitoring ad campaigns across networks and games to manage paid UA scale without hurting profitability",
              "Brainstorming and coming up with innovative ways to test marketing campaigns and thus eventually cracking the mantra for success in the cluttered digital UA landscape",
              "Scaling up the campaigns to deliver higher installs, with good quality at an impressive cost-per-acquisition",
              "Finding niche distribution channels/partners to diversify the risks involved",
              "Building a team of marketing Managers, Account managers, Analysts and Graphic designers, Graphic composers/editors, and marketing communications team",
              "Handling performance appraisals for the team",
            ],
            candidate: [
              "7+ years of overall experience and 5+ years of digital Marketing experience. Global markets UA knowledge would be a plus",
              "Hands on experience in creating campaigns, running liveops on UA and managing marketing budgets greater than 25mn USD per annum",
              "Deep knowledge on dashboards of advertising platforms, MMPs and other performance tools",
              "Great analytical experience to understand the performance of campaigns and ability to drive behavioral insights through data",
              "Hands on experience in handling the constantly changing UA ecosystem (iOS 14 privacy changes, GDPR, and other regulations)",
              "Capable to foresee behavioral patterns and convert them into unique differentiable marketing campaigns",
              "Strong negotiation skills to convert new accounts, business deals, partnerships",
              "Ability to come up with both macro and micro opportunities and breaking down the macro opportunities into achievable micro opportunities",
              "Real experience on hiring raw talent and coaching them to become rockstars in terms of both skill and personality and eventually to become great leaders of the company.",
              "A great team-mate who believes in creating positive culture",
              "A personality with a strong bias towards execution without neglecting/ignoring/affecting values and team mates of the company",
              "A leader who can introspect deeply and become a torch bearer and a load bearer as well depending on the situation",
              "A person who enjoys gaming and is passionate to be in a gaming startup",
              "A matured individual who knows where to draw a line between personal and professional matters",
            ],
            salary: [
              "We are looking out for a rockstar to join our core team. This is a full-time role based out of Bangalore, India. There is no remote working option.",
              "Pay for this role is negotiable based on the experience and competence of the candidate",
            ],
            contact: [
              "Interested candidates, please reach out to us by email at ram@quriousbit.com",
            ],
          }}
        />
        <JobDescriptionCard
          jobTitle="3. HR Manager (Bangalore - 5 Years)"
          jobDescription={{
            contact: [
              "Interested candidates, please reach out to us by email at ram@quriousbit.com",
            ],
          }}
        />
        <JobDescriptionCard
          jobTitle="4. Finance Manager Manager (Bangalore - 5 Years)"
          jobDescription={{
            contact: [
              "Interested candidates, please reach out to us by email at ram@quriousbit.com",
            ],
          }}
        />
      </div>
    </div>
  );
}
