import React, { useRef, useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import "./App.css";
import "./index.css";
import Navbar from "./components/Navbar/Navbar";
import PageHome from "./views/PageHome/PageHome";
import PageAbout from "./views/PageAbout/PageAbout";
import PageDiscover from "./views/PageDiscover/PageDiscover";
import PagePressRelease from "./views/PagePressRelease/PagePressRelease";
import Footer from "./components/Footer/Footer";
import PageCareers from "./views/PageCareers/PageCareers";
import PagePrivacyPolicy from "./views/PagePrivacyPolicy/PagePrivacyPolicy";
import PageTermsOfService from './views/PageTermsOfService/PageTermsOfService';

function ScrollToSection({ refs }) {
  const location = useLocation();

  useEffect(() => {
    const sections = {
      "/": refs.homeRef,
      "/about": refs.aboutRef,
      "/discover": refs.discoverRef,
      "/press-release": refs.pressReleaseRef,
      "/careers": refs.careerRef,
      "/contact": refs.contactRef,
    };

    const ref = sections[location.pathname];
    if (ref && ref.current) {
      window.scrollTo({
        top: ref.current.offsetTop - 60, // Adjust for the navbar height
        behavior: "smooth",
      });
    }
  }, [location, refs]);

  return null;
}

function App() {
  const homeRef = useRef(null);
  const aboutRef = useRef(null);
  const discoverRef = useRef(null);
  const pressReleaseRef = useRef(null);
  const careerRef = useRef(null);
  const contactRef = useRef(null);

  const location = useLocation();

  // Determine if the current route is for the privacy policy page
  const isPrivacyPolicyPage = location.pathname.indexOf("/privacy-policy") >= 0;
  const isTermsOfService = location.pathname.indexOf("/terms-of-service-2") >= 0;
console.log(isPrivacyPolicyPage)
  return (
    <div className="w-[100vw] min-h-screen">
      <Routes>
        <Route path="/privacy-policy" element={<PagePrivacyPolicy />} id="privacy-policy/" />
        <Route path="/terms-of-service-2" element={<PageTermsOfService id="terms-of-service-2" />} />
      </Routes>
      
      <Navbar
        refs={{
          homeRef,
          aboutRef,
          discoverRef,
          pressReleaseRef,
          careerRef,
          contactRef,
        }}
      />

      <ScrollToSection
        refs={{
          homeRef,
          aboutRef,
          discoverRef,
          pressReleaseRef,
          careerRef,
          contactRef,
        }}
      />

      {!isPrivacyPolicyPage && !isTermsOfService && (
        <>
          <section ref={homeRef} id="home">
            <PageHome />
          </section>

          <section ref={aboutRef} id="about">
            <PageAbout />
          </section>

          <section ref={discoverRef} id="discover">
            <PageDiscover />
          </section>

          <section ref={pressReleaseRef} id="press-release">
            <PagePressRelease />
          </section>

          <section ref={careerRef} id="careers">
            <PageCareers />
          </section>
        </>
      )}

      <section ref={contactRef} id="contact">
        <Footer />
      </section>
    </div>
  );
}

export default App;
