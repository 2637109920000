import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import logo from "../../assets/img/quriousbit_logo.png";

export default function Navbar({ scrollToSection, refs }) {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const location = useLocation();

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  // Function to handle menu close
  const handleLinkClick = () => {
    setIsMenuOpen(false);
  };

  return (
    <nav className="w-full bg-black bg-opacity-60 text-white fixed top-0 left-0 z-50">
      <div className="container mx-auto py-2 flex justify-between items-center">
        <div>
          <img src={logo} alt="Logo" width={"160px"} height={"160px"} />
        </div>
        <ul className="hidden md:flex space-x-5">
          <li>
            <Link to="/" className={`hover:text-purple-500`} onClick={handleLinkClick}>
              Home
            </Link>
          </li>
          <li>
            <Link to="/about" className={`hover:text-purple-500`} onClick={handleLinkClick}>
              About Us
            </Link>
          </li>
          <li>
            <Link to="/discover" className={`hover:text-purple-500`} onClick={handleLinkClick}>
              Games
            </Link>
          </li>
          <li>
            <Link to="/press-release" className={`hover:text-purple-500`} onClick={handleLinkClick}>
              Press Release
            </Link>
          </li>
          <li>
            <Link to="/careers" className={`hover:text-purple-500`} onClick={handleLinkClick}>
              Careers
            </Link>
          </li>
          <li>
            <Link to="/contact" className={`hover:text-purple-500`} onClick={handleLinkClick}>
              Contact Us
            </Link>
          </li>
        </ul>
        <div className="md:hidden">
          <button
            onClick={toggleMenu}
            className="text-white focus:outline-none"
            aria-label="Toggle menu"
          >
            <i className="fas fa-bars mr-[5px]"></i>
          </button>
        </div>
      </div>

      {isMenuOpen && (
        <div className="md:hidden bg-black bg-opacity-5 w-full py-4 px-4">
          <ul className="space-y-4 text-center">
            <li>
              <Link to="/" className={`block hover:text-purple-500`} onClick={handleLinkClick}>
                Home
              </Link>
            </li>
            <li>
              <Link to="/about" className={`block hover:text-purple-500`} onClick={handleLinkClick}>
                About Us
              </Link>
            </li>
            <li>
              <Link to="/discover" className={`block hover:text-purple-500`} onClick={handleLinkClick}>
                Games
              </Link>
            </li>
            <li>
              <Link to="/press-release" className={`block hover:text-purple-500`} onClick={handleLinkClick}>
                Press Release
              </Link>
            </li>
            <li>
              <Link to="/careers" className={`block hover:text-purple-500`} onClick={handleLinkClick}>
                Careers
              </Link>
            </li>
            <li>
              <Link to="/contact" className={`block hover:text-purple-500`} onClick={handleLinkClick}>
                Contact Us
              </Link>
            </li>
          </ul>
        </div>
      )}
    </nav>
  );
}
