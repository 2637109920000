import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer className="bg-black bg-opacity-50 text-white py-8 px-4 mt-8">
      <div className="container mx-auto text-left flex flex-col justify-center  lg:gap-4 w-full">
      <h1 className="text-lg md:text-xl font-semibold text-center">CONTACT US</h1>
        <p className="flex items-center gap-[5px]">
          <i className="fa-solid fa-envelope"></i>
          <a href="mailto:support@quriousbit.com" className="text-blue-400">
            support@quriousbit.com
          </a>
        </p>
        <p className="mb-4 flex items-center gap-[5px]">
          <i className="fa-solid fa-location-dot"></i>
          108, First Floor, Block 9B, Raja Ritz Avenue Apartments, Near Hoodi
          Junction Metro Station, Hoodi, Bangalore - 560048
        </p>

        <div className=" w-full flex justify-between space-x-4 mt-4 text-sm">
          <div>
            <p className="">Copyright © 2024 Quriousbit</p>
          </div>
          <div className="flex gap-5">
            <Link
             to="/privacy-policy"
              className="text-blue-400"
            >
              Privacy Policy
            </Link>
            <Link
              to="/terms-of-service-2"
              className="text-blue-400"
            >
              Terms of Service
            </Link>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
