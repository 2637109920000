import React from 'react'

export default function PressReleaseCard({headline, feature, link}) {
    const handleClick = () => {
        if (link) {
          window.open(link, '_blank'); // Open the external URL in a new tab
        }
    }
  return (
    <div className='w-full flex flex-col bg-white text-neutral-900 p-5 rounded-lg gap-5 cursor-pointer' onClick={handleClick}>
        <p>
        {headline}
        </p>
        <span className='text-blue-600 hover:text-blue-700'>
        {feature}
        </span>
    </div>
  )
}
