import React, { useState, useRef, useEffect } from 'react';

export default function JobDescriptionCard({ jobTitle, jobDescription }) {
  const [isOpen, setIsOpen] = useState(false);
  const [height, setHeight] = useState(0); // To store the content height
  const contentRef = useRef(null); // Reference to the content div

  const toggleDescription = () => {
    setIsOpen(!isOpen);
  };

  // Update the height of the content based on its actual height
  useEffect(() => {
    if (isOpen) {
      setHeight(contentRef.current.scrollHeight); // Get actual height of the content
    } else {
      setHeight(0);
    }
  }, [isOpen]);

  return (
    <div className="w-full border border-gray-300 rounded-lg overflow-hidden shadow-lg mx-auto my-4">
      <div
        className="bg-gray-100 p-4 cursor-pointer hover:bg-gray-200 transition-colors flex justify-between items-center"
        onClick={toggleDescription}
      >
        <h3 className="text-sm md:text-base font-semibold text-gray-800">{jobTitle}</h3>
        <i
          className={`fas ${isOpen ? 'fa-chevron-up' : 'fa-chevron-down'} text-gray-600`}
        />
      </div>
      <div
        className="overflow-hidden transition-all duration-300 ease-in-out"
        style={{ maxHeight: `${height}px` }}
        ref={contentRef}
      >
        <div className="p-4 bg-white text-gray-800">
          {Object.entries(jobDescription).map(([key, value]) => (
            <div key={key} className="mb-4">
              <h4 className="text-sm md:text-base font-semibold">{capitalizeFirstLetter(key)}:</h4>
              <ul className="list-disc ml-5 font-normal text-sm md:text-base">
                {Array.isArray(value) ? (
                  value.map((item, index) => (
                    <li key={index} className="mb-2">{item}</li>
                  ))
                ) : (
                  <li className="mb-2">{value}</li>
                )}
              </ul>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

// Helper function to capitalize the first letter of each key
const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1).replace(/([A-Z])/g, ' $1');
};
