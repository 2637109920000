import React from "react";
import Slider from "react-slick";
import game1 from "../../assets/img/game-1.png";
import game2 from "../../assets/img/game-2.png";
import game3 from "../../assets/img/game-3.png";
import game4 from "../../assets/img/game-4.png";
import game5 from "../../assets/img/game-5.png";
import game6 from "../../assets/img/game-6.png";

const GameCarousel = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
  };

  return (
    <div className="relative">
      <div className="phone-frame">
        <Slider {...settings}>
          <div className="slider-item">
            <img src={game1} alt="Game 1" />
          </div>
          <div className="slider-item">
            <img src={game2} alt="Game 2" />
          </div>
          <div className="slider-item">
            <img src={game3} alt="Game 3" />
          </div>
          <div className="slider-item">
            <img src={game4} alt="Game 4" />
          </div>
          <div className="slider-item">
            <img src={game5} alt="Game 5" />
          </div>
        </Slider>
      </div>
    </div>
  );
};

export default GameCarousel;
