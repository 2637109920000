import React from "react";

const Founder = ({ name, description, details, linkedin, email}) => {
  return (
    <div className="w-[90%] md:w-[500px] h-auto md:h-auto bg-white rounded-lg shadow-lg overflow-hidden mx-auto my-4 p-4">
      <div className="text-center mt-4">
        <h2 className="text-lg md:text-xl font-semibold text-gray-800">
          {name}
        </h2>
        <p className="text-gray-600 mt-2">{description}</p>
        <p className="text-gray-600 text-sm mt-2">{details}</p>

        <div className="flex justify-center mt-4 space-x-4">
          <a
            href={linkedin}
            target="_blank"
            rel="noopener noreferrer"
            className="text-blue-600 hover:text-blue-700 text-xl cursor-pointer"
          >
            <i className="fab fa-linkedin"></i>
          </a>
          <a
            href={`mailto:${email}`}
            className="text-blue-600 hover:text-blue-700 text-xl cursor-pointer"
          >
            <i className="fa-solid fa-envelope"></i>
          </a>
        </div>
      </div>
    </div>
  );
};

export default Founder;
