import React from "react";
import press_release from "../../assets/img/press-release.png";
import PressReleaseCard from "../../components/PressReleaseCard/PressReleaseCard";

export default function PagePressRelease() {
  return (
    <div className="w-[90%] md:w-[80%] mx-auto flex flex-col gap-6 md:gap-8 md:mt-20">
      <div>
        <h1 className="text-lg md:text-xl font-semibold text-center">PRESS RELEASE</h1>
      </div>
      <div className="md:w-1/2 w-full flex flex-col md:flex-row gap-4">
        {/* Image section */}
        <div className="md:w-1/2 w-full">
          <img src={press_release} alt="Press Release" className="rounded-lg w-full" />
        </div>
        {/* Press Release Cards section */}
        <div className="md:w-1/2 w-full flex flex-col justify-between gap-4 md:px-4">
          <PressReleaseCard
            headline={
              "QuriousBit bags $2 million funding from Lumikai to develop casual mobile Games"
            }
            feature={"Featured in INC42"}
            link={
              "https://inc42.com/buzz/quriousbit-bags-funding-from-lumikai-to-develop-casual-mobile-games/"
            }
          />
          <PressReleaseCard
            headline={
              "QuriousBit to utilise $2 million seed fundraise for hiring and developing tech infrastructure"
            }
            feature={"Featured in CNBC"}
            link={
              "https://www.cnbctv18.com/startup/startup-fundraise-quriousbit-gaming-vahdam-india-tea-17898191.htm"
            }
          />
          <PressReleaseCard
            headline={
              "Casual gaming studio QuriousBit bags $2 million funding from Lumikai, General Catalyst"
            }
            feature={"Featured in Moneycontrol"}
            link={
              "https://www.moneycontrol.com/news/business/startup/casual-gaming-studio-quriousbit-bags-2-million-funding-from-lumikai-general-catalyst-11437971.html"
            }
          />
        </div>
      </div>
    </div>
  );
}
