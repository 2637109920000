import React, { useState } from "react";
import homeImage from "../../assets/img/background.jpeg";

export default function PageHome() {
  const [imageLoaded, setImageLoaded] = useState(false);

  const scrollToNextSection = () => {
    window.scrollTo({
      top: window.innerHeight,
      behavior: "smooth",
    });
  };

  return (
    <div className="relative w-full h-screen flex items-center justify-center overflow-hidden">
      {/* Loader Spinner */}
      {!imageLoaded && (
        <div className="absolute inset-0 flex items-center justify-center bg-black">
          <div className="w-12 h-12 border-4 border-t-4 border-white border-solid rounded-full animate-spin"></div>
        </div>
      )}

      <img
        src={homeImage}
        alt="Home"
        className="absolute inset-0 w-full h-full object-cover"
        loading="lazy"
        style={{ opacity: imageLoaded ? 1 : 0, transition: "opacity 1s ease-in-out" }}
        onLoad={() => setImageLoaded(true)}
      />

      <div className="relative text-5xl md:text-5xl text-white font-extrabold p-3 md:p-4">
        <span className="block text-center drop-shadow-lg tracking-wider">
          WHERE GAMING GETS CURIOUS
        </span>
        <div
          className="flex justify-center scroll-arrow text-white cursor-pointer drop-shadow-lg tracking-wider mt-6"
          onClick={scrollToNextSection}
        >
          <span className="w-auto h-auto bg-black/40 px-[12px] py-[6px] flex items-center justify-center rounded-full text-3xl md:text-4xl animate-pulse">
            <i className="fa-solid fa-angles-down"></i>
          </span>
        </div>
      </div>
    </div>
  );
}
