import React from "react";

export default function ButtonBox({ icon, label, link }) {
  const handleClick = () => {
    if (link) {
      window.open(link, '_blank'); // Open the external URL in a new tab
    }
  };

  return (
    <div
      className="border border-neutral-900 flex items-center p-[10px] gap-[5px] rounded-lg bg-black/50 shadow-2xl cursor-pointer transition-all duration-300 ease-in-out transform hover:bg-black/70 hover:shadow-lg hover:scale-105"
      onClick={handleClick}
    >
      <img src={icon} alt="" width={30} height={30}/>
      <div>{label}</div>
    </div>
  );
}
