import React from "react";
import ButtonBox from "../../components/ButtonBox/ButtonBox";
import GameCarousel from "../../components/GameCarousel/GameCarousel";
import block_blitz_log from "../../assets/img/block-bltiz.png";
import googlePlay from "../../assets/img/google_play_icon_bg.png";

export default function PageDiscover() {
  const navigateToBlockBlitz = () => {
    window.open(
      "https://play.google.com/store/apps/details?id=com.quriousbit.jumbotileblast&hl=en",
      "_blank"
    ); // Open the external URL in a new tab
  };
  return (
    <div className="w-[90%] md:w-[80%] mx-auto flex flex-col mt-10 md:mt-20 gap-6 md:gap-8">

      {/* Our Games */}

      <div className="my-8 flex flex-col lg:items-centerp-4 lg:p-8 gap-6 ">
        <h1 className="text-lg md:text-xl font-semibold text-center">GAMES</h1>
        <div className="flex flex-col justify-center lg:flex-row lg:gap-8 w-full">
          <div className="flex-grow mb-4 lg:mb-0">
            <GameCarousel />
          </div>
          <div className="flex flex-col items-center justify-center w-full lg:w-auto p-4 lg:p-6 gap-4 lg:gap-6">
            <div>
              <img
                src={block_blitz_log}
                alt=""
                width={"100px"}
                height={"100px"}
              />
            </div>
            <div className="flex items-start font-bold text-xl lg:text-2xl text-center">
              {" "}
              Block Blitz - Block Puzzle Game
            </div>
            <p className="text-center">
              Love Block games? Enjoy IQ Challenging Puzzles? Fan of Sudoku,
              Tetris, Jigsaw, or Just love a good puzzle? , then Block Blitz is
              designed for you!
            </p>
            <ButtonBox
              icon={googlePlay}
              label="Get it on Google Play"
              link={
                "https://play.google.com/store/apps/details?id=com.quriousbit.jumbotileblast&hl=en"
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
}
